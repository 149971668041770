import React from "react";
import {
  Box,
  Stack,
  Text,
  Grid,
  GridItem,
  AspectRatio,
  Flex,
  Spacer,
} from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import Container from "components/ui/Container";
import Seo from "components/Seo";

const SolutionPage: React.FC = () => {
  return (
    <Box pb={10}>
      <Seo
        title="Solution For Interior Designers &amp; Furniture Brands"
        description="AiHouse 3D interior design visualization and realistic renderings software easily create, edit and manage your design in real time to anywhere, sharing high-quality renderings and panorama with immersive experience."
        keywords="interior design software, interior design rendering software, 3D interior and exterior design rendering software, online floor plan design software, cloud-based interior design and rendering software platform, online 4K rendering software"
      />

      <Box py={16} backgroundColor="#FFF7ED">
        <Container fluid>
          <Text as="h1" fontSize="3xl" fontWeight="bold" textAlign="center">
            AiHouse is here for Interior Designers
          </Text>

          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
            gap={6}
            mt={6}
          >
            <Stack spacing={4}>
              <Box height="52px" width="52px">
                <StaticImage
                  src="../images/icons/solution/details.svg"
                  alt="details icon aihouse india"
                  width={52}
                  height={52}
                />
              </Box>

              <Stack spacing={2} color="gray.600">
                <Text fontSize="lg">Details are hard</Text>

                <Text>
                  There seems to be no faster way to customize the details of
                  your design. Traditional software are too complicated. Simple
                  floor planners are usually impractical.
                </Text>
              </Stack>
            </Stack>

            <Stack spacing={4}>
              <Box height="52px" width="52px">
                <StaticImage
                  src="../images/icons/solution/meaningless.svg"
                  alt="meaninggless icon aihouse india"
                  width={52}
                  height={52}
                />
              </Box>

              <Stack spacing={2} color="gray.600">
                <Text fontSize="lg">Meaningless waiting</Text>

                <Text fontWeight="light">
                  Besides paying for pricy computer setup, you spend a long time
                  waiting for renders. The worst thing is having to go through
                  it again when your clients have endless revision requests.
                </Text>
              </Stack>
            </Stack>

            <Stack spacing={4}>
              <Box height="52px" width="52px">
                <StaticImage
                  src="../images/icons/solution/3d-model.svg"
                  alt="3d model icon aihouse india"
                  width={52}
                  height={52}
                />
              </Box>

              <Stack spacing={2} color="gray.600">
                <Text fontSize="lg">3D model hunting</Text>

                <Text fontWeight="light">
                  From one website to another, you are always searching for the
                  perfect props, furniture, texture…just for one tiny corner in
                  your scene. And it’s never enough.
                </Text>
              </Stack>
            </Stack>

            <Stack spacing={4}>
              <Box height="52px" width="52px">
                <StaticImage
                  src="../images/icons/solution/juggling.svg"
                  alt="juggling icon aihouse india"
                  width={52}
                  height={52}
                />
              </Box>

              <Stack spacing={2} color="gray.600">
                <Text fontSize="lg">Juggling different software</Text>

                <Text>
                  To bring your design to life, you are constantly switching
                  between different software and extensions. CAD, BIM, Photoshop
                  and even Excel…
                </Text>
              </Stack>
            </Stack>
          </Grid>
        </Container>
      </Box>

      <Container fluid mt={10}>
        <Stack spacing={10}>
          <Grid
            gap={{ base: 2, md: 8 }}
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
            alignItems="center"
          >
            <GridItem>
              <StaticImage
                src="../images/banners/solution/customize-easily.png"
                alt="customize easily icon aihouse india"
              />
            </GridItem>

            <GridItem>
              <Stack spacing={2}>
                <Text as="h4" fontSize="2xl">
                  Customize easily
                </Text>

                <Text fontSize="lg" fontWeight="light">
                  Besides a drag and drop experience, AiHouse offers 10
                  intelligent modules to help you customize your design down to
                  the last detail. You can easily edit various storage
                  combinations, complicated tiling, multilayer ceiling,
                  intricate exterior structure
                </Text>
              </Stack>
            </GridItem>
          </Grid>

          <Grid
            gap={{ base: 2, md: 8 }}
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
            alignItems="center"
          >
            <GridItem rowStart={1} colStart={{ base: 1, md: 2 }}>
              <StaticImage
                src="../images/banners/solution/render-in-minutes.png"
                alt="render in minutes icon aihouse india"
              />
            </GridItem>

            <GridItem rowStart={{ base: 2, md: 1 }} colStart={1}>
              <Stack spacing={2}>
                <Text as="h4" fontSize="2xl">
                  Render in minutes
                </Text>

                <Text fontSize="lg" fontWeight="light">
                  AiHouse can deliver stunning 4K renders in minutes. You don’t
                  have to worry about your hardware setup as everything happens
                  on the cloud. You can even add interactive features on the
                  panoramas & walk-throughs to make them more engaging to your
                  clients.
                </Text>
              </Stack>
            </GridItem>
          </Grid>

          <Grid
            gap={{ base: 2, md: 8 }}
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
            alignItems="center"
          >
            <GridItem>
              <StaticImage
                src="../images/banners/solution/a-huge-library.png"
                alt="a huge library icon aihouse india"
              />
            </GridItem>

            <GridItem>
              <Stack spacing={2}>
                <Text as="h4" fontSize="2xl">
                  A huge library
                </Text>

                <Text fontSize="lg" fontWeight="light">
                  AiHouse boasts over 80 million 3D models and texture in the
                  library, covering almost everything that would be needed in
                  interior projects. Of course, you can always upload your own
                  collections to build a personal library.
                </Text>
              </Stack>
            </GridItem>
          </Grid>

          <Grid
            gap={{ base: 2, md: 8 }}
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
            alignItems="center"
          >
            <GridItem rowStart={1} colStart={{ base: 1, md: 2 }}>
              <StaticImage
                src="../images/banners/solution/various-2d-documents.png"
                alt="various 2d documents icon aihouse india"
              />
            </GridItem>

            <GridItem rowStart={{ base: 2, md: 1 }} colStart={1}>
              <Stack spacing={2}>
                <Text as="h4" fontSize="2xl">
                  Various 2D documents
                </Text>

                <Text fontSize="lg" fontWeight="light">
                  We think like a professional. AiHouse can generate CAD
                  drawings, cutting lists, BOM and quotations directly from your
                  projects with a click. We take care of the tedious work so you
                  can focus on polishing your design ideas.
                </Text>
              </Stack>
            </GridItem>
          </Grid>
        </Stack>
      </Container>

      <Box mt={10} py={16} backgroundColor="#FFF7ED">
        <Container fluid>
          <Text as="h1" fontSize="3xl" fontWeight="bold" textAlign="center">
            Having trouble growing your business?
          </Text>

          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
            gap={6}
            mt={6}
          >
            <Stack spacing={4}>
              <Box height="52px" width="52px">
                <StaticImage
                  src="../images/icons/solution/lengthy-design.svg"
                  alt="lengthy design icon aihouse india"
                  width={52}
                  height={52}
                />
              </Box>

              <Stack spacing={2} color="gray.600">
                <Text fontSize="lg">Lengthy design process</Text>

                <Text fontWeight="light">
                  It's extremely time-consuming to design customized solutions
                  for your clients.
                </Text>
              </Stack>
            </Stack>

            <Stack spacing={4}>
              <Box height="52px" width="52px">
                <StaticImage
                  src="../images/icons/solution/hard-to-impress.svg"
                  alt="hard to impress icon aihouse india"
                  width={52}
                  height={52}
                />
              </Box>

              <Stack spacing={2} color="gray.600">
                <Text fontSize="lg">Hard to impress customers</Text>

                <Text fontWeight="light">
                  Customers are no longer satisfied with boring and static
                  proposals, especially young consumers.
                </Text>
              </Stack>
            </Stack>

            <Stack spacing={4}>
              <Box height="52px" width="52px">
                <StaticImage
                  src="../images/icons/solution/ineffective-marketing.svg"
                  alt="ineffective marketing icon aihouse india"
                  width={52}
                  height={52}
                />
              </Box>

              <Stack spacing={2} color="gray.600">
                <Text fontSize="lg">Ineffective marketing</Text>

                <Text fontWeight="light">
                  Targeting the right audience is getting more and more costly
                  and difficult.
                </Text>
              </Stack>
            </Stack>

            <Stack spacing={4}>
              <Box height="52px" width="52px">
                <StaticImage
                  src="../images/icons/solution/high-video-production-costs.svg"
                  alt="high video production costs icon aihouse india"
                  width={52}
                  height={52}
                />
              </Box>

              <Stack spacing={2} color="gray.600">
                <Text fontSize="lg">High video production costs</Text>

                <Text fontWeight="light">
                  A premium promotional video is likely to cost you over $50000.
                </Text>
              </Stack>
            </Stack>
          </Grid>
        </Container>
      </Box>

      <Container fluid mt={10}>
        <Stack spacing={10}>
          <Grid
            gap={{ base: 2, md: 8 }}
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
            alignItems="center"
          >
            <GridItem>
              <StaticImage
                src="../images/banners/solution/gear-up-your-team.png"
                alt="gear up your team icon aihouse india"
              />
            </GridItem>

            <GridItem>
              <Stack spacing={2}>
                <Text as="h4" fontSize="2xl">
                  Gear up your team
                </Text>

                <Text fontSize="lg" fontWeight="light">
                  Equip your team and distributors with AiHouse, which would
                  reduce design and render time from days to hours, and from
                  hours to minutes. We can help you get started by building a 3D
                  product catalog for you.
                </Text>
              </Stack>
            </GridItem>
          </Grid>

          <Grid
            gap={{ base: 2, md: 8 }}
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
            alignItems="center"
          >
            <GridItem rowStart={1} colStart={{ base: 1, md: 2 }}>
              <Stack direction="row" spacing={4}>
                <Box rounded="xl" overflow="hidden">
                  <StaticImage
                    src="../images/banners/solution/engage-your-customers-1.jpg"
                    alt="engage your customar 1 icon aihouse india"
                  />
                </Box>

                <Flex direction="column">
                  <Box rounded="xl" overflow="hidden">
                    <StaticImage
                      src="../images/banners/solution/engage-your-customers-1.jpg"
                      alt="engage your customar 1 icon aihouse india"
                      height={84}
                      width={84}
                    />
                  </Box>
                  <Spacer />
                  <Box rounded="xl" overflow="hidden">
                    <StaticImage
                      src="../images/banners/solution/engage-your-customers-2.jpg"
                      alt="engage your customar 2 icon aihouse india"
                      height={84}
                      width={84}
                    />
                  </Box>
                  <Spacer />
                  <Box rounded="xl" overflow="hidden">
                    <StaticImage
                      src="../images/banners/solution/engage-your-customers-3.jpg"
                      alt="engage your customar 3 icon aihouse india"
                      height={84}
                      width={84}
                    />
                  </Box>
                  <Spacer />
                  <Box rounded="xl" overflow="hidden">
                    <StaticImage
                      src="../images/banners/solution/engage-your-customers-4.jpg"
                      alt="engage your customar 4 icon aihouse india"
                      height={84}
                      width={84}
                    />
                  </Box>
                </Flex>
              </Stack>
            </GridItem>

            <GridItem rowStart={{ base: 2, md: 1 }} colStart={1}>
              <Stack spacing={2}>
                <Text as="h4" fontSize="2xl">
                  Engage your customers
                </Text>

                <Text fontSize="lg" fontWeight="light">
                  With AiHouse, you can now immerse customers in their future
                  home with not only stunning 4K images but also interactive
                  panoramas and 720° walk-throughs, all rendered in minutes.
                </Text>
              </Stack>
            </GridItem>
          </Grid>

          <Grid
            gap={{ base: 2, md: 8 }}
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
            alignItems="center"
          >
            <GridItem>
              <StaticImage
                src="../images/banners/solution/expand-your-tech.png"
                alt="expand your tech icon aihouse india"
              />
            </GridItem>

            <GridItem>
              <Stack spacing={2}>
                <Text as="h4" fontSize="2xl">
                  Expand your reach
                </Text>

                <Text fontSize="lg" fontWeight="light">
                  Put your products in our public library so your brand would be
                  presented to all AiHouse designers in the world. You don’t
                  need to spend that much time and money to tap into your target
                  market.
                </Text>
              </Stack>
            </GridItem>
          </Grid>

          <Grid
            gap={{ base: 2, md: 8 }}
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
            alignItems="center"
          >
            <GridItem rowStart={1} colStart={{ base: 1, md: 2 }}>
              <AspectRatio ratio={16 / 9} rounded="xl" overflow="hidden">
                <video autoPlay muted loop>
                  <source
                    src={
                      require("../images/banners/solution/make-your-content-pop.mp4")
                        .default
                    }
                    type="video/mp4"
                  />
                </video>
              </AspectRatio>
            </GridItem>

            <GridItem rowStart={{ base: 2, md: 1 }} colStart={1}>
              <Stack spacing={2}>
                <Text as="h4" fontSize="2xl">
                  Make your content pop
                </Text>

                <Text fontSize="lg" fontWeight="light">
                  Create animated walk-throughs with jaw-dropping realism to add
                  the WOW factor to your online content. You can make as many
                  high quality promotional videos as you want without spending a
                  dime.
                </Text>
              </Stack>
            </GridItem>
          </Grid>
        </Stack>
      </Container>
    </Box>
  );
};

export default SolutionPage;
